<template>
  <div>
    <PageSpinner :onloader="onloader" />
    <div class="row">
      <div class="col-lg-8 justify-content-center pb-3 text-center"></div>
      <div class="col-lg-4">
        <div class="animate__animated  animate__slideInDown px-5 py-5 text-white bg-dark">
          <div class="text-center text-muted my-3">
            <p class="mt-3">Login to School Account</p>
          </div>

          <form role="form" @submit.prevent="userlogin">
            <base-input class="input-group-alternative mb-3" placeholder="Username" addon-left-icon="ni ni ni-single-02"
              v-model="username">
            </base-input>
            <base-input class="input-group-alternative" placeholder="Password" type="password"
              addon-left-icon="ni ni-lock-circle-open" v-model="password">
            </base-input>
            <div class="mb-3">
              <router-link to="/retrieve-password" class="text-primary"><small class="pl-3">Forgot
                  password?</small></router-link>
            </div>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <button type="submit" class="btn btn-primary my-3" :disabled="$store.state.appdatas.disenableifNotOnline">
                Sign in <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSpinner from "@/components/PageSpinner"
import RandomWord from "@/mixins/random-word-generator.mixin"
import Alert from "@/mixins/Alert.mixins"
export default {
  name: "login",
  components: { PageSpinner },
  mixins: [RandomWord, Alert],
  data() {
    return {
      username: "",
      password: "",
      errormsg: "",
      onloader: "none",
    };
  },
  methods: {
    userlogin() {
      let username = `${this.RandomStringNumber(30)}${this.$store.state.auth.UsPad}${btoa(this.username)}`;
      let password = `${this.RandomStringNumber(30)}${this.$store.state.auth.PwdPad}${btoa(this.password)}`;

      this.onloader = "flex";
      let payload = {
        username,
        password
      };
      this.$store
        .dispatch("auth/Auth", payload)
        .then((resp) => {
          if (resp.responseCode == '00') {
            var accessDetails = resp.data.data;
            this.$store.dispatch("auth/MutateUsername", accessDetails.userName);
            this.onloader = "none";
            if (accessDetails.isDefaultPasswordChanged == false) {
              this.$router.push("/change-password");
            } else {
              if (accessDetails.isSchoolStaff) {
                this.GetLoginStaffDetails();
              } else {
                this.$router.push("/dashboard");
              }

            }

          } else {
            this.onloader = "none";
            this.ShowAlertError(resp.responseMessage, '');
          }
        })
        .catch((err) => {
          this.password = "";
          this.onloader = "none";
          if (err.response.data === "Yet to Logon") {
            this.$router.push("/change-password");
          } else {
            this.ShowAlertError(err.response.data, '');
          }
        });
    },
    getbank() {
      this.onloader = "flex";
      this.$store
        .dispatch("getbank")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getschooltypes() {
      this.onloader = "flex";
      this.$store
        .dispatch("getschooltypes")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getstate() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstates")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getprivacypolicy() {
      this.onloader = "flex";
      this.$store
        .dispatch("getprivacypolicy")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getstaffdetails() {
      this.onloader = "flex";
      this.$store
        .dispatch("getstaffdetails")
        .then(() => {
          this.onloader = "none";
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    getreportrequest() {
      let passcode = this.$route.query.rep;
      if (passcode != null || passcode != undefined) {
        this.onloader = "flex";
        this.$store
          .dispatch("passcodeaccess", passcode)
          .then((resp) => {
            this.onloader = "none";
            if (resp.ReportType == "Full") {
              this.$router.push("/studreportcard?rep=" + passcode);
            }
            if (resp.ReportType == "Mid") {
              this.$router.push("/studreportcardmid?rep=" + passcode);
            }
          })
          .catch((err) => {
            this.onloader = "none";
            this.ShowAlertError(err.response.data, '');
          });
      }
    },
    GetUserStatus() {
      this.onloader = "flex";
      this.$store
        .dispatch("GetUserStatus")
        .then(() => {
          this.onloader = "none";
        })
        .catch((err) => {
          this.onloader = "none";
          console.log(err);
        });
    },
    GetLoginStaffDetails() {
      this.onloader = "flex";
      this.$store
        .dispatch("SchoolStaff/GetLoginStaffDetails")
        .then(() => {
          this.$router.push("/dashboard");
          this.onloader = "none";
        })
        .catch((err) => {
          console.log(err);
          this.onloader = "none";
        });
    },
  },
  mounted() {
    this.getprivacypolicy();
    this.getstate();
    this.GetUserStatus();
    this.getbank();
    this.getschooltypes();
    this.getreportrequest();
  },
};
</script>

<style></style>
